import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'

import useStaticModule from '@hooks/useStaticModule'
import TracklineBackground from '@objects/tracklineBackground'
import Container from '@objects/container'
import Headline from '@objects/headline'
import SingleDownload from '@components/singleDownload'
import MediaPageGallery from '@components/mediaPageGallery'

export const frontmatter = {
  breadcrumbs: [
    { label: 'Downloads & Presse', link: '/downloads-und-presse/' },
  ],
}

function MediaPage({ data, pageContext }) {
  const intl = useIntl()
  const { getStaticFieldValue, getStaticFieldMedia } = useStaticModule(
    pageContext.modules
  )

  const MediaPageList = get(data, 'allContentfulMediaListCategory.nodes')
    .sort((a, b) => a.order - b.order)
    .map((item) => ({
      title: item.title,
      mediaList: item.mediaLists?.map((media) => ({
        title: media.title,
        slug: `${intl.formatMessage({ id: 'path.media' })}${media.slug}`,
        teaserImage: media.teaserImage,
        amount: media.files?.length,
      })),
    }))

  const ModuleList = !!data.contentfulModuleList
    ? get(data, 'contentfulModuleList.files')
        .sort((a, b) => a.order - b.order)
        .map((media) => ({
          fields: media.contentfulfields?.map((item) => ({
            id: item.id,
            key: item.key,
            media: item.media,
            value: item.value,
          })),
          id: media.id,
          key: media.key,
        }))
    : ''

  const SingleDownloadProps = {
    headline: getStaticFieldValue(
      'media.guidelines',
      'media.guidelines.box.headline'
    ),
    copy: getStaticFieldValue('media.guidelines', 'media.guidelines.box.copy'),
    image: getStaticFieldMedia(
      'media.guidelines',
      'media.guidelines.box.image'
    ),
    file: {
      url: getStaticFieldMedia('media.guidelines', 'media.guidelines.box.pdf')
        ?.file?.url,
      size: getStaticFieldMedia(
        'media.guidelines',
        'media.guidelines.box.pdf'
      )?.file?.details.size.toString(),
    },
  }

  function renderGalleries() {
    return MediaPageList.map((list) => (
      <MediaPageGallery
        key={list.title}
        className="mt-11"
        title={list.title}
        mediaList={list.mediaList}
      />
    ))
  }

  function renderModules() {
    const moduleList = ModuleList.map((item) => ({
      headline: item.fields.find((obj) => {
        return obj.key === `${item.key}.box.headline`
      })?.value.value,
      copy: item.fields.find((obj) => {
        return obj.key === `${item.key}.box.copy`
      })?.value.value,
      image: item.fields.find((obj) => {
        return obj.key === `${item.key}.box.image`
      })?.media,
      file: {
        url: item.fields.find((obj) => {
          return obj.key === `${item.key}.box.pdf`
        })?.media.file.url,
        size: item.fields
          .find((obj) => {
            return obj.key === `${item.key}.box.pdf`
          })
          ?.media.file.details.size.toString(),
      },
    }))

    function renderModuleList() {
      return moduleList.map((item) => (
        <div key={item.headline} className="pb-6">
          <SingleDownload {...item} />
        </div>
      ))
    }
    return (
      <>
        <Headline
          className="mb-7 mt-11 text-xl font-medium leading-9 md:text-3xl"
          level={2}
          ariaLabel={data.contentfulModuleList.title}
        >
          {data.contentfulModuleList.title}
        </Headline>
        <div>{renderModuleList()}</div>
      </>
    )
  }

  return (
    <>
      <Container
        nopadding
        className="pt-6 pb-6 md:pb-11"
        role="region"
        aria-label={intl.formatMessage({ id: 'media.headline' })}
      >
        <Headline
          level={1}
          ariaLabel={intl.formatMessage({ id: 'media.headline' })}
        >
          <FormattedMessage id="media.headline" />
        </Headline>
      </Container>
      <Container nopadding className="pb-11" role="region">
        <TracklineBackground track={2} mirror top="-50px" />
        <TracklineBackground track={5} right mirror top="200px" />
        <TracklineBackground track={4} bottom="10%" width={400} />
        <Headline
          className="mb-7 text-xl font-medium leading-9 md:text-3xl"
          level={2}
          ariaLabel={getStaticFieldValue(
            'media.guidelines',
            'media.guidelines.headline'
          )}
        >
          {getStaticFieldValue('media.guidelines', 'media.guidelines.headline')}
        </Headline>
        <SingleDownload {...SingleDownloadProps} />

        {!!data.contentfulModuleList ? renderModules() : ''}
        {renderGalleries()}
      </Container>
    </>
  )
}

MediaPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.array,
}

export default MediaPage

export const pageQuery = graphql`
  query MediaPageQuery($locale: String!) {
    allContentfulMediaListCategory(filter: { node_locale: { eq: $locale } }) {
      nodes {
        title
        identifier
        order
        mediaLists {
          slug
          title
          teaserImage {
            fluid(quality: 80, maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          files {
            ... on ContentfulImage {
              id
            }
            ... on ContentfulVideo {
              id
            }
          }
        }
      }
    }

    contentfulModuleList(slug: { eq: "media-logos" }) {
      id
      slug
      title
      files {
        id
        key
        contentfulfields {
          ... on ContentfulStaticField {
            id
            media {
              id
              fluid(quality: 80, maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              file {
                url
                details {
                  size
                }
              }
              description
            }
            key
            value {
              id
              value
            }
          }
        }
      }
    }
  }
`
