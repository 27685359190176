import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import breakpoint from '@utils/breakpoint'

import Headline from '@objects/headline'
import Icon from '@objects/icon'
import ChevronRight from '@material-ui/icons/ArrowForwardIosRounded'

const StyledMediaIcon = styled(Icon).attrs({
  name: 'Media',
  className: 'text-lg',
})`
  margin-left: 4px;
  margin-bottom: 4px;
`

const StyledImageWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors['gray-light']?.default};
  height: 242px;
  width: 242px;
`

const StyledMediaItem = styled(Link)`
  .gatsby-image-wrapper {
    ${tw`
      min-w-full transition-transform duration-700 ease-in-out
    `}
    transform:: scale(1);
  }

  margin: 16px 24px 16px 0;
  width: 242px;

  ${breakpoint('md')`
    width: 242px;

    &:hover {
      text-decoration: none;
      .gatsby-image-wrapper {
          transform: scale(1.05);
      }
    }
  `}
`

function MediaPageGallery({ className, title, mediaList }) {
  function renderMediaList() {
    return mediaList.map((item) => (
      <StyledMediaItem key={item.slug} to={item.slug} data-testid="listitem">
        {!!item.teaserImage?.fluid && (
          <StyledImageWrapper className="relative overflow-hidden max-h-full border border-gray-light">
            <div className="absolute top-0 right-0 p-1 bg-blue flex gap-0.5 z-10 text-white items-center">
              <StyledMediaIcon />
              {item.amount}
            </div>
            <Img
              fluid={{ ...item.teaserImage.fluid, media: `(min-width: 0px)` }}
              alt={item.teaserImage.description}
              backgroundColor="grey"
              className="h-full"
              aspectRatio={1 / 1}
            />
          </StyledImageWrapper>
        )}

        <p className="mt-2 font-medium max-w-[242px]">
          <ChevronRight /> {item.title}
        </p>
      </StyledMediaItem>
    ))
  }

  return (
    <div className={className}>
      <Headline
        className="mb-8 text-xl font-medium leading-9 md:text-3xl"
        level={2}
        ariaLabel={title}
      >
        {title}
      </Headline>
      <div className="flex flex-row justify-start items-start flex-wrap">
        {renderMediaList()}
      </div>
    </div>
  )
}

MediaPageGallery.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  mediaList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      teaserImage: PropTypes.shape({
        fluid: PropTypes.object,
      }),
      amount: PropTypes.number,
    })
  ),
}

export default MediaPageGallery
